export const handleTabClick = (tabSelector, paneSelector) => {
  const tabs = [...document.querySelectorAll(tabSelector)]
  const panes = [...document.querySelectorAll(paneSelector)]
  let currentActiveTab = null

  const activateTab = (targetTab, updateURL = true) => {
    const activeClass = `${tabSelector.slice(1)}--active`

    tabs.forEach(tab => tab.classList.remove(activeClass))
    panes.forEach(pane => {
      pane.classList.remove('fade-in', 'fade-out')
      pane.style.display = 'none'
    })

    const activeTab = document.querySelector(
      `${tabSelector}[data-tab="${targetTab}"]`
    )
    activeTab?.classList.add(activeClass)

    const activePane = document.querySelector(
      `${paneSelector}[data-tabbed-content-index="${targetTab}"]`
    )
    if (activePane) {
      activePane.style.display = 'flex'
      requestAnimationFrame(() => activePane.classList.add('fade-in'))

      const swiperElement = activePane.querySelector('.mySwiper')
      swiperElement?.swiper?.update()
    }

    currentActiveTab = targetTab

    if (updateURL) {
      const tabName = activeTab?.dataset.tabName
      if (tabName) {
        const url = new URL(window.location)
        url.searchParams.set('tab', tabName)
        window.history.replaceState({}, '', url)
      }
    }
  }

  const getTabFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const tabName = urlParams.get('tab')
    if (!tabName) return null

    const matchedTab = tabs.find(tab => tab.dataset.tabName === tabName)
    return matchedTab ? matchedTab.dataset.tab : null
  }

  let initialActiveTab =
    getTabFromURL() ||
    document.querySelector(`${tabSelector}--active`)?.dataset.tab

  if (initialActiveTab) {
    currentActiveTab = initialActiveTab
    activateTab(initialActiveTab, false)
  }

  tabs.forEach(tab => {
    tab.addEventListener('click', () => {
      const targetTab = tab.dataset.tab
      if (targetTab === currentActiveTab) return

      const currentActivePane = document.querySelector(
        `${paneSelector}[data-tabbed-content-index="${currentActiveTab}"]`
      )
      if (currentActivePane) {
        currentActivePane.classList.add('fade-out')
        setTimeout(() => currentActivePane.classList.remove('fade-out'), 300)
      }

      activateTab(targetTab)
    })
  })
}
